<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="权限管理"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div style="margin:10px">
        <el-button
          size="medium"
          type="success"
          @click="addUserRole"
          >添加权限</el-button
        >
    </div>
    <div>
      <el-table
        border
        :data="
          privilegeData.filter(
            (data) =>
              !search ||
              data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        max-height="800px"
      >
        <el-table-column prop="name" label="名称" width="auto">
        </el-table-column>
        <el-table-column prop="company.name" label="所属组织" width="150px">
        </el-table-column>
        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入组织名称搜索"
            />
          </template>
          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              @click="addUserRole(scope.row)"
              >编辑</el-button
            >
             <el-button
              size="medium"
              type="danger"
              @click="delUserRole(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog title="添加组织" 
        @close="closeUserDialog('userRoleData')"
       :visible.sync="userDialogStatus"
        width="50%">
        <el-form ref="userRoleData" :model="userRoleData" label-width="100px" :rules="companyRules">
          <el-row :gutter="12">
            <el-col :span="8">
              <el-form-item label="角色名称" prop="name">
                <el-input v-model="userRoleData.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属组织" prop="cid">
                <el-select v-model="userRoleData.cid">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div>

          </div>
          <el-row :gutter="12">
            <el-col :span="24" v-for="city in cities" :key="city.id">
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox  :label="city.id" >{{city.name}}</el-checkbox>
              </el-checkbox-group>
            </el-col>
          </el-row>
         
          <el-row :gutter="12">
            <el-col :span="6" >
              <el-form-item>
                <el-button type="primary" @click="submitForm('userRoleData')">保存</el-button>
                <el-button @click="closeUserDialog('userRoleData')">关闭</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dataStorage from '@/../utils/dataStorage.js'
import BaseData from '@/assets/config/BaseData.js';
import axios from "axios";

const cityOptions = [
  {
    id:621,
    name:'视频监控'
  },{
    id:622,
    name:'声音播放'
  },{
    id:624,
    name:'音频监听'
  },{
    id:627,
    name:'实时录像'
  },{
    id:641,
    name:'录像回放'
  },{
    id:135,
    name:'设备管理'
  },
  ];
export default {
  data() {
    return {
        checkedCities: [],
        cities: cityOptions,
      row:true,
      userDialogStatus:false,

      userRoleData:{
        jsession:dataStorage.getBaseData("videoJsession"),
        name:"",   // 角色名称
        cid:"",  // 所属组织id
        privilege:"",  // 角色权限
        id:"",  // 角色id（编辑用的）
      },
      options:[], // 公司名
      companyRules:{
        name: [
          { required: true, message: '请填写权限名称', trigger: 'change' }
        ],
        cid: [
          { required: true, message: '请选择所属组织', trigger: 'change' }
        ],
      },
      privilegeData: [],
      search: "",
    };
  },
  created() {
    this.findUserRole()
    this.findCompany()
    this.aaa()
  },
  methods: {
    aaa(){
      let a = [
        {name:"aaa" ,age:18},
        {name:"bbb" ,age:19},
        {name:"ccc" ,age:20},
        {name:"ddd" ,age:28},
        {name:"Ae" ,age:18},
      ]
      var b = a.filter( (item) => 
                            item.name.includes("a".toLowerCase())
                      )
    },
    handleCheckedCitiesChange(value) {
      this.userRoleData.privilege = JSON.parse(JSON.stringify(value)) + '';
    },
     submitForm(userRoleData) {
      this.$refs[userRoleData].validate((valid) => {
        if (valid) {
          console.log(this.userRoleData);
          // 新增组织接口 有id 为修改
          axios
          .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_mergeUserRole.action", {
            params:this.userRoleData,
          })
          .then((res) => {
            this.$refs[userRoleData].resetFields()
            this.userDialogStatus = false
            if (res.data.result == 0) {
              this.$message.success("操作成功")
              this.findUserRole()
            }else{
              this.$message.error("操作失败")
            }
          });
        }
      });
    },
    findUserRole() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findUserRole.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
           this.privilegeData = res.data.role
        });
    },
    findCompany() {
      axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_findCompany.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
          },
        })
        .then((res) => {
           this.options = res.data.infos
        });
    },
    delUserRole(row) {
      this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios
        .get( "http://" +  BaseData.videoIP + ":"+  BaseData.videoPorts + "/StandardApiAction_deleteUserRole.action", {
          params: {
            jsession: dataStorage.getBaseData("videoJsession"),
            id:row.id
          },
        })
        .then((res) => {
           if (res.data.result == 0) {
            this.$message.success("删除成功")
            this.findUserRole()
           }
        });
      }).catch(() => {
          this.$message.info("取消删除")
      });
    },
    
    addUserRole(row) {
      this.userDialogStatus = true

      if (row.id) {
        // 编辑
        this.userRoleData.cid = row.company.id
        this.userRoleData.name = row.name
        this.userRoleData.id = row.id
        // console.log(row.privilege); // ["123,456,789"]
        var str = row.privilege.split(",") // 字符串按逗号分割 ["123","456","789"] 
        for (let i = 0; i < str.length; i++) {
          str[i] = parseInt(str[i])   // string 转 number
        }
        this.checkedCities = str // [123,456,789]
      }else{
        this.userRoleData.cid = ""
        this.userRoleData.name = ""
        this.checkedCities=[]
      }
    },
    closeUserDialog(userRoleData){
      this.userDialogStatus = false
      this.$refs[userRoleData].resetFields()
    },

    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    
  },
};
</script>

<style lang="scss" scope>
</style>
